<template>
  <b-container fluid>
    <b-row>
      <b-col v-if="formData.org_id">
          <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="formData.org_id" :office-id="formData.office_id">
            {{ $t('globalTrans.other') }} {{ $t('elearning_tpm.training_schedule') + ' ' + $t('globalTrans.details') }}
          </list-report-head>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top>
                  <tbody>
                    <tr>
                      <th style="width: 22%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                      <th class="text-center" style="width: 3%">:</th>
                      <td style="width: 23%" class="text-left">{{ formData.circular_memo_no }}</td>
                      <th style="width: 22%" class="text-left"></th>
                      <th style="width: 3%" class="text-center"></th>
                      <td style="width: 25%" class="text-left"></td>
                    </tr>
                    <tr>
                      <th style="width: 22%" class="text-left" >{{ $t('elearning_config.fiscal_year') }}</th>
                      <th class="text-center" style="width: 3%">:</th>
                      <td style="width: 25%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                      <th style="width: 22%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                      <th style="width: 3%" class="text-center">:</th>
                      <td style="width: 25%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                    </tr>
                    <tr>
                      <th style="width: 22%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                      <th style="width: 3%" class="text-center">:</th>
                      <td style="width: 25%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                      <th style="width: 22%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                      <th style="width: 3%" class="text-center">:</th>
                      <td style="width: 25%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
                <b-overlay :show="load">
                  <b-row>
                    <div class="col-md-12" id="form">
                      <fieldset class="pt-2 w-100">
                        <legend class="px-2 w-50 shadow-sm">{{ $t('globalTrans.other') }} {{$t('elearning_tpm.training_schedule')}}</legend>
                        <slot v-if="routines.length>0">
                          <div v-for="(routine,index) in routines" :key="index">
                            <b-row class="pl-2 pr-2">
                              <b-col lg="6" sm="6" class="text-left pl-10">
                                <b>{{ $t('globalTrans.day') }}-{{$n(index+1)}}</b>
                              </b-col>
                              <b-col lg="6" sm="6"  class="text-right pr-10">
                                 <b>{{routine.date | dateFormat}} ({{dayGetName(routine.date)}})</b>
                              </b-col>
                              <b-col lg="12" sm="12">
                                <table class="table tables-data" style="width:100%" border="1">
                                  <thead class="thead">
                                    <tr>
                                      <b-th style="width:10%" class="text-center">{{ $t('globalTrans.time') }}</b-th>
                                      <b-th style="width:10%" class="text-center">{{$t('elearning_config.topics_session')}}</b-th>
                                      <b-th style="width:10%" class="text-center">{{$t('elearning_config.speaker_facilitator')}}</b-th>
                                    </tr>
                                  </thead>
                                  <tr v-for="(schedule,index1) in routine.list" :key="index1">
                                    <td class="text-center">{{schedule.start_time | time12FormateTraining}} - {{schedule.end_time | time12FormateTraining}}</td>
                                    <td class="text-center">
                                      {{ $i18n.locale === 'bn' ? schedule.topic_bn : schedule.topic_en }}
                                    </td>
                                    <td class="text-center">
                                      {{ $i18n.locale === 'bn' ? schedule.speaker_bn : schedule.speaker }}
                                    </td>
                                  </tr>
                                </table>
                              </b-col>
                            </b-row>
                          </div>
                        </slot>
                      </fieldset>
                    </div>
                  </b-row>
                  <!-- <b-row>
                    <div class="col-md-12" id="form">
                      <fieldset class="pt-2 w-100">
                        <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.faculty_members_nata')}}</legend>
                        <slot v-if="committee">
                            <b-row class="pl-2 pr-2">
                              <b-col lg="12" sm="12">
                                <table class="table tables-data" style="width:100%" border="1">
                                  <thead class="thead">
                                    <tr>
                                      <b-th style="width:10%" class="text-center">{{ $t('globalTrans.name') }}</b-th>
                                      <b-th style="width:10%" class="text-center">{{$t('globalTrans.designation')}}</b-th>
                                      <b-th style="width:10%" class="text-center">{{$t('globalUserData.mobile_no')}} / {{ $t('globalTrans.email') }}</b-th>
                                    </tr>
                                  </thead>
                                  <tr v-for="(committee,index1) in committee.details" :key="index1">
                                    <td class="text-center">{{ $i18n.locale === 'bn' ? committee.name_bn : committee.name_en }}</td>
                                    <td class="text-center">
                                      {{ $i18n.locale === 'bn' ? committee.designation_bn : committee.designation_en }}
                                    </td>
                                    <td class="text-center">
                                      {{ ($i18n.locale === 'bn' ? '০' : '0') + $n(committee.mobile_no, { useGrouping: false }) }} <br>
                                      {{ committee.email }}
                                    </td>
                                  </tr>
                                </table>
                              </b-col>
                            </b-row>
                        </slot>
                      </fieldset>
                    </div>
                  </b-row> -->
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
.tables-data th, .tables-data td {
  padding: 0px 0px 0px 10px !important;
}
.tables-data {
  margin-bottom: 0px !important
}
</style>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { otherTrainingScheduleRoutine } from '../../api/routes'
import moment from 'moment'

export default {
  components: {
    ListReportHead
  },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getCircularWiseData(tmp)
      this.committeeData(tmp)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0
      },
      officeTypeList: [],
      officeList: [],
      routines: [],
      committee: [],
      load: false,
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
  },
  methods: {
    getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return office !== undefined ? office.text_bn : ''
        } else {
            return office !== undefined ? office.text_en : ''
        }
    },
    dayGetName (value) {
      return moment(value).format('dddd')
    },
    async getCircularWiseData (search) {
      this.load = true
      const routineSearch = {
        circular_memo_no: search.circular_memo_no,
        office_id: search.office_id
      }
      RestApi.getData(trainingElearningServiceBaseUrl, otherTrainingScheduleRoutine, routineSearch).then(response => {
        if (response.success) {
          this.routines = response.data
          this.load = false
        } else {
          this.load = false
        }
      })
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tpm.training_schedule')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this, this.routines, this.committee)
    }
  }
}
</script>
