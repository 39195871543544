import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat, time12FormateTraining } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, routines, committee) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          { columns: reportHeadData.reportHeadColumn },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: vm.getOfficeName(data.office_id), style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
          [
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: data.circular_memo_no, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_iabm.batch_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.batch_name_bn : data.batch_name, style: 'td', alignment: 'left' }
          ]
        ]
        bodySeperate.push([
          { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' },
          { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
        ])
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' }
          ]
        )
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['22%', '3%', '25%', '22%', '3%', '25%'],
              body: bodySeperate
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        routines.map((routine, index) => {
          const rightSide = dateFormat(routine.date) + ' (' + vm.dayGetName(routine.date) + ')'
          const allRowsHead = [
            [
              { bold: true, text: vm.$t('globalTrans.day') + '-' + vm.$n(index + 1), alignment: 'left', style: 'th' },
              { bold: true, text: rightSide, alignment: 'right', style: 'th' }
            ]
          ]
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['50%', '50%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
          const allRows = [
            [
              { text: vm.$t('globalTrans.time'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('elearning_config.topics_session'), style: 'th', alignment: 'center', bold: true },
              { text: vm.$t('elearning_config.speaker_facilitator'), style: 'th', alignment: 'center', bold: true }
            ]
          ]
          routine.list.forEach((info, index) => {
            allRows.push([
              { text: time12FormateTraining(info.start_time) + ' - ' + time12FormateTraining(info.end_time), style: 'td', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? info.topic_bn : info.topic_en, style: 'td', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? info.speaker_bn : info.speaker, style: 'td', alignment: 'center' }
            ])
          })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['35%', '30%', '35%'],
              body: allRows
            }
          })
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 9,
              bold: true,
              margin: [5, -25, 5, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
